import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Hero from "components/hero/AillaHero.js";
import MissionFeature from "components/features/AillaMissionFeature.js";
import ComingSoonFeature from "components/features/AillaComingSoonFeature.js";
import Footer from "components/footers/MiniCenteredFooter.js";

export default () => (
  <AnimationRevealPage disabled={true}>
    <Hero />
    <MissionFeature />
    <ComingSoonFeature />
    <Footer />
  </AnimationRevealPage>
);
