import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";

import Header from "../headers/light.js";

import ReactModalAdapter from "../../helpers/ReactModalAdapter.js";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/dot-pattern.svg";
import googlePlayIconImageSrc from "images/google-play-badge.png"
import androidImageSrc from "images/android-apk-download.png"
import appleImageSrc from "images/apple-store-badge.svg"
import mockupImageSrc from "images/icon-512.png"
import phoneImageSrc from "images/otaiku-screen-portrait.png"

const LinksContainer = tw.div`mt-8 lg:mt-8 flex flex-col space-y-4 lg:space-y-0 lg:space-x-4 md:items-center justify-center items-center lg:justify-start items-center lg:flex-row`
const Link = styled.a`
  ${tw`block`}
  img {
    ${tw`h-20 object-contain`}
  }
`;

const Container = tw.div`relative bg-gray-100`;
const AppContainer = tw.div`flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-20 md:py-24 flex justify-center`;
const TwoColumn = tw.div`flex flex-col lg:flex-row md:items-center p-10 max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-6/12 lg:pr-12 flex-shrink-0 text-center lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex flex-col justify-center`;

const Heading = tw.h1`font-black text-3xl md:text-5xl leading-snug max-w-3xl`;
const Paragraph = tw.p`my-5 lg:my-8 text-sm lg:text-base font-medium text-gray-600 max-w-lg mx-auto lg:mx-0`;

const Actions = tw.div`flex flex-col items-center sm:flex-row justify-center lg:justify-start mt-8`;
const PrimaryButton = tw.button`font-bold px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;

const LinksContainer2 = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`
const Link2 = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`;
const FooterHeader = tw.h3`text-gray-300 pb-1 text-center font-bold uppercase`;
const LinkList = tw.ul`flex pt-8 flex-wrap items-center justify-center mb-6`;
const LinkListItem = tw.li`mt-3 items-center justify-center text-center`;
const IllustrationContainer = tw.div`flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3  -z-10`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none fill-current text-primary-500 opacity-25 absolute w-32 h-32 right-0 bottom-0 transform translate-x-10 translate-y-10 -z-10`}
`;

const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-gray-200 outline-none`}
  }
  .content {
    ${tw`w-full lg:p-16`}
  }
`;
const ColumnContainer = tw.div`max-w-2xl`
const ImageContainer = tw.div`h-16 justify-center`;
const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;

export default ({
  heading = "Create mesmerizing AI art with Otaiku.",
  description = "Otaiku is the ultimate AI art generation app that lets you transform your words and images into stunning works of art. With our powerful deep learning algorithms and intuitive interface, you can create unique digital art pieces in seconds. Try it now and unleash your creativity!",
  imageSrc = phoneImageSrc,
  appImageSrc = mockupImageSrc,
  imageCss = null,
  imageDecoratorBlob = false,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const toggleModal = () => setModalIsOpen(!modalIsOpen);

  return (
    <>
      <Header links={[]} />
      <Container>
        <TwoColumn>
          <LeftColumn>
            <Heading>{heading}</Heading>
            <Paragraph>{description}</Paragraph>
            <LinksContainer>
              <Link href={"https://play.google.com/store/apps/details?id=com.ailla.otaiku"}>
                <img src={googlePlayIconImageSrc} alt="" />
              </Link>
              <Link href={"https://apps.apple.com/us/app/otaiku-anime-ai-art/id6452801234"}>
                <img src={appleImageSrc} alt="" />
              </Link>
              <Link href={"https://bucket.ailla.biz/otaiku-1-50-0.apk"}>
                <img src={androidImageSrc} alt="" />
              </Link>
            </LinksContainer>
            <LinkList href="#terms">
              <ImageContainer>
                <img src={appImageSrc} alt="" tw="h-full rounded-xl" />
              </ImageContainer>
              <LinkListItem>
                <Link2 href="/otaiku/privacy-policy">Privacy Policy</Link2>
              </LinkListItem>
              <LinkListItem>
                <Link2 href="/otaiku/terms-of-service">Terms of Service</Link2>
              </LinkListItem>
            </LinkList>
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
              <img
                css={imageCss}
                src={imageSrc}
                alt="Hero"
                tw="h-[50vh]"
              />
              {imageDecoratorBlob && <DecoratorBlob2 />}
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
        <DecoratorBlob1 />
        <StyledModal
          closeTimeoutMS={300}
          className="mainHeroModal"
          isOpen={modalIsOpen}
          onRequestClose={toggleModal}
          shouldCloseOnOverlayClick={true}
        >
          <CloseModalButton onClick={toggleModal}>
            <CloseIcon tw="w-6 h-6" />
          </CloseModalButton>
        </StyledModal>
      </Container>
    </>
  );
};
