import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Hero from "components/hero/OtaikuHero.js";
import Footer from "components/footers/MiniCenteredFooter.js";

export default () => (
  <AnimationRevealPage disabled={true}>
    <Hero/>
    <Footer />
  </AnimationRevealPage>
);