import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { PaddedContainer, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading } from "components/misc/Headings";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none justify-center`}
`;
const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Privacy Policy" }) => {
  // TODO: Fix the lighting on header
  return (
    <AnimationRevealPage disabled={true}>
      <StyledHeader className={"header-light"} links={[]} />
      <PaddedContainer>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>
              Last updated:  March 25th, 2023
            </p>
            <p>
              Thank you for choosing Otaiku, an AI art generation app developed by Ailla Oy ("us", "we", "our"). We value your privacy and are committed to protecting it.
              This Privacy Policy describes the ways we collect, use, and disclose information about you and your use of the Otaiku app and its services.
            </p>
            <p>
              By using the Otaiku app, you consent to our collection and use of this information as described in this Privacy Policy.
              If you do not agree to the collection and use of this information, you should not use the Otaiku app.
            </p>
            <h2>COLLECTION OF INFORMATION</h2>
            <p>We may collect the following information from you when you use the Otaiku app:</p>
            <ul>
              <li><p><strong>Email address:</strong> We collect your email address when you register for an account with Otaiku. This is used for logging in and identification purposes.</p></li>
              <li><p><strong>Google authentication:</strong> If you choose to use Google Sign-In, we will collect your Google email address for authentication and identification purposes. No other Google data, such as your full name or profile picture, will be stored by us. </p></li>
              <li><p><strong>User-generated content:</strong> If you choose to upload images to the app for art generation, we will store them temporarily on our servers for the purpose of generating the output. The generated output will be immediately shown to the user, and the original image will be deleted from our servers. The generated images are stored locally on the user's device unless the user chooses to publish them for other users to see.</p></li>
              <li><p><strong>Third-party ads:</strong> Otaiku may display third-party advertisements in the app, which may use cookies or other tracking technologies to collect information about your online activities over time and across different websites or online services. This information is used to deliver personalized ads to you. You can opt-out of personalized ads by disabling tracking in the app's settings.</p></li>
              <li><p><strong>Usage data and interactions, and error logs:</strong> We also collect certain usage data and interactions with the Otaiku app, as well as error logs, to help us understand how users interact with the app, to diagnose and fix errors, and to improve the overall user experience. This may include information such as the pages or screens you view, the actions you take, and the time and date of your interactions. We may also collect device information, such as your device type and operating system version, to help us optimize the app for your device. We do not use this information to personally identify you or to target personalized ads.</p></li>
            </ul>
            <h2>USE OF INFORMATION</h2>
            <p>We use the information we collect for the following purposes:</p>
            <ul>
              <li>To provide and improve the Otaiku app and its services.</li>
              <li>To personalize your experience with Otaiku, including delivering personalized ads.</li>
              <li>To communicate with you, including responding to your inquiries and sending you service-related messages.</li>
              <li>To enforce our Terms of Service.</li>
            </ul>
            <h2>DISCLOSURE OF INFORMATION</h2>
            <p>We may disclose your information to the following parties:</p>
            <ul>
              <li><strong>Service providers:</strong> We may disclose your information to third-party service providers who help us provide and improve the Otaiku app and its services.</li>
              <li><strong>Legal requirements:</strong> We may disclose your information to law enforcement or other government agencies if we are required to do so by law, or if we believe that such disclosure is necessary to protect our rights or the rights of others, or to prevent fraud or other illegal activity.</li>
              <li><strong>Business transfers:</strong> We may disclose your information to a third party in connection with a merger, acquisition, reorganization, sale of assets, or similar transaction.</li>
            </ul>
            <h2>SECURITY OF INFORMATION</h2>
            <p>We take reasonable measures to protect the information we collect from you against unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the Internet or method of electronic storage is completely secure, and we cannot guarantee absolute security of your information.</p>
            <h2>CHILDREN'S PRIVACY</h2>
            <p>
              Our service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If you are a parent or guardian and you are aware that your child has provided us with personal data, please contact us. If we become aware that we have collected personal data from anyone under the age of 13 without verification of parental consent, we take steps to remove that information from our servers.
            </p>
            <p>
              If we need to rely on consent as a legal basis for processing your information and your country requires consent from a parent, we may require your parent's consent before we collect and use that information.
            </p>
            <h2>CHANGES TO THIS PRIVACY POLICY</h2>
            <p>We may update this Privacy Policy from time to time, and we encourage you to review it periodically. If we make material changes to the Privacy Policy, we will notify you by email or through the app. Your continued use of the Otaiku app after any changes to the Privacy Policy will signify your acceptance of the changes.</p>
            <h2>CONTACT US</h2>
            <p>If you have any questions or concerns about this Privacy Policy or our practices, please contact us at contact@ailla.biz.</p>
          </Text>
        </ContentWithPaddingXl>
      </PaddedContainer>
      <Footer />
    </AnimationRevealPage>
  );
};
