import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { PaddedContainer, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none justify-center`}
`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

const content = () => {
  return (
    <>
      <p>
        Last updated:  May 3rd, 2023
      </p>
      <p>Thank you for choosing to use Otaiku, an AI art generation app ("the app") developed by Ailla Oy ("Ailla") . By using the app, you agree to the following Terms of Service ("the agreement").
        Please read this agreement carefully before using the app. If you do not agree with these terms, do not use the app.
        Your use of the app constitutes your acceptance of these terms.</p>
      <h2>Use of the App</h2>
      <p>In accessing and using the app, you agree to abide by the following rules, restrictions and limitations</p>
      <ul>
        <li>You will comply with all applicable laws in your access and use of the app</li>
        <li>You will not upload or share any content that is harmful, libelous, defamatory, obscene, abusive, racially or ethnically offensive, pornographic, indecent, lewd, harassing, threatening, invasive of personal privacy or publicity rights, misleading, incendiary, or otherwise objectionable</li>
        <li>You will not create, upload, or distribute content that facilitates the exploitation or abuse of children</li>
        <li>You will not attempt to access any unauthorized parts of the app</li>
      </ul>
      <h2>Ownership and Use of Content</h2>
      <p>The app generates images based on user input. Users must, as individuals or in a group, contribute creative expression in conjunction with use of the app,
        such as in creating or selecting prompts or user inputs to use with the tools offered by the app. Users retain intellectual property ownership rights over contents
        they submit to the app and creations created with the app. Users acknowledge that artworks generated without creative expression from the
        user may not be eligible for copyright protection. Regardless of the creativity of users, Ailla cannot guarantee the uniqueness, originality, or quality,
        or the availability or extent of copyright protection for any artwork created by users with assistance of the app.
      </p>
      <p>
        By sharing generated content inside the app, users grant the app a non-exclusive, royalty-free, transferable, sub-licensable, worldwide
        license to use, distribute, modify, run, copy, publicly display, translate, or otherwise create derivative works of the content in a manner that is consistent with our Privacy Policy.
      </p>
      <h2>Account</h2>
      <p>You may be required to create an account to use certain features of the app. When creating an account, you must provide accurate and complete information. You agree not to impersonate any other person or entity or to
        use a false name or a name that you are not authorized to use. You further represent and warrant that the email address or other credentials you provide belong to you.</p>
      <p>You are responsible for maintaining the confidentiality of your account and password, and for restricting access to your account. You agree
        to accept responsibility for all activities that occur under your account or password. You agree to notify the app immediately of any unauthorized
        use of your account or password or any other breach of security.</p>
      <p>The app reserves the right to terminate or suspend access to your account at any time, with or without notice and without liability to you, for any
        reason or no reason, including without limitation, if the app determines that you have violated these terms of service or applicable law, or if your account
        has been inactive for an extended period of time.</p>
      <p>You acknowledge and agree that the app is not liable for any damages or losses resulting from any unauthorized access to or use of your account.</p>
      <h2>Indemnification</h2>
      <p>Users agree to indemnify and hold the app and its owners harmless from any claims, losses, damages, liabilities,
        and expenses arising out of their use of the app or violation of this agreement.</p>
      <h2>Limitation of Liability</h2>
      <p>The app and its owners shall not be liable for any direct, indirect, incidental, special, consequential,
        or exemplary damages arising out of or in connection with your use of the app, including without limitation
        damages for loss of profits, goodwill, use, data, or other intangible losses, even if the app or its owners have
        been advised of the possibility of such damages. Under no circumstances shall the app or its owners' total
        liability to you for all damages, losses, and causes of action arising out of or in connection with this agreement
        or your use of the app exceed the amount paid by you, if any, for accessing the app.</p>
      <p>The app is provided "as is" and without warranty of any kind. The app and its owners expressly disclaim all
        warranties and conditions of any kind, whether express or implied, including, but not limited to, the implied
        warranties and conditions of merchantability, fitness for a particular purpose, and non-infringement.</p>
      <p>You acknowledge and agree that the limitations of liability and disclaimers set forth in this agreement
        represent a reasonable allocation of risk and form an essential basis of the bargain between you and the app.
        The app would not be provided without such limitations and disclaimers.</p>
      <p>If you are dissatisfied with the app or any of its contents, or with these terms and conditions, your sole
        and exclusive remedy is to discontinue using the app.</p>
      <h2>Dispute Resolution</h2>
      <p>Any disputes arising out of or related to this agreement shall be resolved through binding arbitration.
        The arbitration shall take place in the jurisdiction where the app's owners are located, and the arbitrator's decision
        shall be final and binding.</p>
      <h2>Termination</h2>
      <p>Either the user or the app can terminate this agreement at any time for any reason. Upon termination,
        the user's account and all associated data will be deleted from the app's servers.</p>
      <h2>Changes to the Agreement</h2>
      <p>The app's owners reserve the right to modify this agreement at any time. Any changes will be posted to the app
        and will become effective immediately upon posting. By continuing to use the app after changes to the agreement have
        been posted, users agree to be bound by the modified terms.</p>
      <h2>Violation of the Agreement</h2>
      <p>If a user violates any part of this agreement, their account may be terminated and they may be prohibited from using the
        app in the future.</p>
      <p>If you have any questions or concerns about this agreement, please contact us at contact@ailla.biz.</p>
    </>
  )
}

export default ({ headingText = "Terms of Service" }) => {
  return (
    <AnimationRevealPage disabled={true}>
      <StyledHeader className={"header-light"} links={[]} />
      <PaddedContainer>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            {content()}
          </Text>
        </ContentWithPaddingXl>
      </PaddedContainer>
      <Footer />
    </AnimationRevealPage>
  );
};
