import React, { useState } from "react";
import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import tw from "twin.macro";
import Footer from "components/footers/MiniCenteredFooter.js";
import Header from "components/headers/light.js";
import googleIconImageSrc from "images/google-icon.png";
import styled from "styled-components";

const API_URL = "https://silmin.art/api/v1";

const responseHandler = async (response) => {
  const isJson = response.headers.get("content-type")?.includes("application/json");
  const data = isJson ? await response.json() : null;
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.detail) || response.status;
    return Promise.reject(error);
  }
  return data;
};

const exchangeCode = async (authorizationCode) => {
  const data = await fetch(
    API_URL +
      "/auth/google/callback?" +
      new URLSearchParams({
        code: authorizationCode,
      })
  ).then(responseHandler);
  return data;
};

const getAccount = async (token) => {
  return fetch(API_URL + "/users/me", {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "get",
  }).then(responseHandler);
};

const logIn = async (email, password) => {
  let formData = new FormData();
  formData.append("username", email);
  formData.append("password", password);
  const data = await fetch(API_URL + "/auth/jwt/login", {
    body: formData,
    method: "post",
  }).then(responseHandler);
  return data;
};

const deleteAccount = async (token) => {
  return fetch(API_URL + "/users/me", {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "delete",
  }).then(responseHandler);
};

const Container = tw.div`flex-grow py-10 bg-gray-100 flex justify-center items-center px-10`;
const Column = tw.div`flex-col bg-white p-10 shadow-md rounded-md`;
const InputField = tw.input`w-full p-2 border rounded-md mb-4 focus:outline-none focus:border-blue-500`;
const EmailLoginButton = tw.button`w-full flex-row flex items-center justify-between bg-white font-medium text-gray-700 border border-gray-500 px-4 py-2 rounded-md mb-4 focus:outline-none focus:ring focus:border-blue-300`;
const DeleteAccountButton = tw.button`w-full px-4 py-2 rounded-md mb-4 focus:outline-none focus:ring`;
const Paragraph = tw.p`my-5 lg:my-8 text-lg lg:text-base font-medium text-gray-900 max-w-lg mx-auto lg:mx-0`;
const Label = tw.p`text-sm lg:text-base font-medium text-gray-600 max-w-lg mx-auto lg:mx-0`;
const ConfirmationInput = tw.input`w-full my-4 p-2 border rounded-md mb-4 focus:outline-none focus:border-red-500 placeholder-gray-500`;
const IconContainer = styled.div`
  .icon {
    ${tw`w-6`}
  }
`;

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState(null);
  const [confirmation, setConfirmation] = useState("");
  const [user, setUser] = useState(null);
  const [deleted, setDeleted] = useState(false);
  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => authResponseHandler(codeResponse),
    flow: "auth-code",
  });

  const authResponseHandler = async (response) => {
    // Handle Google OAuth login
    const backendResponse = await exchangeCode(response.code);
    const info = await getAccount(backendResponse.access_token);
    console.log(info);
    setToken(backendResponse.access_token);
    setUser(true);
  };

  const handleEmailPasswordLogin = async () => {
    // Call your login function for email/password
    try {
      const data = await logIn(email, password);
      setToken(data.access_token);
      setUser(true);
      console.log(data);
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  const handleDeleteAccount = async (token) => {
    if (confirmation.toLowerCase() === "delete") {
      try {
        // Call your delete account function here
        await deleteAccount(token);
        setUser(null); // Reset user state after deleting account
        setEmail("");
        setPassword("");
        setDeleted(true);
      } catch (error) {
        console.error("Delete account failed:", error);
      }
    } else {
      alert('To confirm deletion, type "delete" into the input field.');
    }
  };

  return (
    <AnimationRevealPage disabled={true}>
      <Header links={[]} />
      <Container>
        <Column>
          {!deleted ? (
            user ? (
              <>
                <Paragraph>We are sorry to see you leave!</Paragraph>
                <Label>Email</Label>
                <p>{email}</p>
                <ConfirmationInput
                  type="text"
                  value={confirmation}
                  onChange={(e) => setConfirmation(e.target.value)}
                  placeholder="Type 'delete' to confirm"
                />
                <DeleteAccountButton
                  onClick={() => handleDeleteAccount(token)}
                  disabled={confirmation.toLowerCase() !== "delete"}
                  style={{
                    backgroundColor: confirmation.toLowerCase() !== "delete" ? "#ccc" : "#e53e3e",
                    color: confirmation.toLowerCase() !== "delete" ? "#787878" : "#ffffff",
                  }}
                >
                  Delete Account
                </DeleteAccountButton>
              </>
            ) : (
              <>
                <Label>Email</Label>
                <InputField type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter your email" />

                <Label>Password</Label>
                <InputField
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter your password"
                />
                <EmailLoginButton onClick={handleEmailPasswordLogin}>Login with Email</EmailLoginButton>

                <EmailLoginButton onClick={googleLogin}>
                  <IconContainer>
                    <img src={googleIconImageSrc} className={"icon"} alt="" />
                  </IconContainer>
                  <p>Login with Google</p>
                </EmailLoginButton>
              </>
            )
          ) : (
            <Paragraph>Your account and all associated data has been deleted.</Paragraph>
          )}
        </Column>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};

const GoogleLoginPage = () => (
  <GoogleOAuthProvider clientId="286856118658-cmck7r2m1nf76s6p6gg59pj68u9rrrfm.apps.googleusercontent.com">
    <LoginPage />
  </GoogleOAuthProvider>
);

export default GoogleLoginPage;
